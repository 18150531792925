import React from 'react';
import MenuButton from '../components/menu_button'

function Homepage() {
    return (
        <div className="App">
            <img src={process.env.PUBLIC_URL + '/background.png'} className="back-image" alt="Botaniku - rooftop bar" />
            <MenuButton />
        </div>
    );
}

export default Homepage;