import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage';
import MenuPage from './pages/menu';
import Footer from './components/footer';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/about" element={<AboutPage />} /> */}
          <Route path="/menu" element={<MenuPage />} />
          <Route path="*" element={<Homepage />} />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </Router>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
