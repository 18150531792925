import React from 'react';
import { Link } from 'react-router-dom';

function GreenButton() {
    const buttonStyle = {
        color: '#73b275',              // Green text color
        border: '2px solid #73b275',   // Green border
        padding: '10px 20px',        // Adjust padding as needed
        textDecoration: 'none',      // Remove default link styling
        display: 'inline-block',     // Display as a block element
        margin: '10px',              // Adjust margin as needed
        borderRadius: '5px',         // Add border radius for rounded edges
    };

    return (
        <Link to="/menu" style={buttonStyle}>
            Menu
        </Link>
    );
}

export default GreenButton;