import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function MenuPage() {
    return (
        <div className="App">
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                {/* <!-- The viewer component will be put here -->
                ... */}
                <Viewer fileUrl={process.env.PUBLIC_URL + '/menu.pdf'} />;
            </Worker>
            {/* // Your render function */}
            {/* <Document file="/menu.pdf" /> */}
            {/* <iframe title="menu-pdf" src={process.env.PUBLIC_URL + '/menu.pdf'} type="application/pdf" width="100%" height="100%" /> */}
            {/* <object data="/menu.pdf" type="application/pdf" width="100%" height="500">
                <p>Alternative text if the browser doesn't support PDFs</p>
            </object> */}
        </div>
    );
}

export default MenuPage;